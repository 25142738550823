<template>
	<viewer-layout
		:primary-toolbar-config="toolbar"
    :clinic-code = "clinicCode"
		@import-into-keystone="importIntoKeystone"
		@download-dicom-images="downloadStudy"
		@download-jpeg-images="downloadStudy('previewImage')"
	>
		<template #dialogs>
			<modal-dialog
				v-if="requestInfoDialog.isOpen"
				v-bind="requestInfoDialog"
				@close="requestInfoDialog.isOpen = false"
				@download="downloadStudy"
				@contact="contactSender"
			>
				<h4 style="margin-bottom: 0;">{{ consultationRequest.subject }}</h4>
				<em style="display: block; margin-bottom: 15px; font-weight: 300;">
					Sent by {{ consultationRequest.sentBy }} on {{ consultationRequest.sentOn }}
				</em>
				<!-- eslint-disable -->
				<!-- for v-html -->
				<pre style="white-space: pre-wrap; word-break: break-word;" v-html="consultationRequest.message"></pre>
				<!-- eslint-enable -->
				<h4 style="margin-top: 55px;">Associated Patient Data</h4>
				<ast-data-table :is-hoverable="false">
					<thead>
						<tr>
							<th>Patient</th>
							<th>Owner</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(study, index) in consultationRequest.studies" :key="index">
							<td>{{ study.patientName }}</td>
							<td>{{ study.ownerName }}</td>
						</tr>
					</tbody>
				</ast-data-table>

				<template v-if="consultationRequest.reports && consultationRequest.reports.length > 0">
					<h4 style="margin-top: 55px;">Reports</h4>
					<ast-data-table :is-hoverable="false">
						<thead>
							<tr>
								<th style="width: 70%;">Date</th>
								<th>Report</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(report, index) in consultationRequest.reports" :key="index">
								<td style="width: 70%;">{{ report.name }}</td>
								<td>
									<a style="text-decoration: underline;" :href="report.reportUrl" target="_blank">
										Report
									</a>
								</td>
							</tr>
						</tbody>
					</ast-data-table>
				</template>

				<p
					v-if="consultationRequest.hasDeletedStudies"
					class="is-danger"
					:style="{ fontSize: '0.9rem', margin: '0 1rem' }"
				>
					One or more studies attached to this email have been deleted by the originating practice.
				</p>
			</modal-dialog>
		</template>
	</viewer-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ViewerLayout from '@router/layouts/Viewer'
import AstDataTable from '@components/DataTable'
import ModalDialog from '@components/ModalDialog'
import storage from '@services/storage'
import GoogleAnalytics from '@services/analyticsService'

export default {
	name: 'ConsultationRequest',
	components: {
		AstDataTable,
		ModalDialog,
		ViewerLayout,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		clinicCode: {
			type: String,
			required: true,
		},
		recipientId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			viewedRecipientIds: [],
			requestInfoDialog: {
				name: 'requestInfoDialog',
				isOpen: false,
				scroll: true,
				title: 'Shared Medical Images',
				actions: [
					{
						name: 'Contact',
						type: 'button',
						icon: 'email',
						emit: 'contact',
					},
				],
			},
		}
	},
	computed: {
		...mapState({
			consultationRequest: state => state.viewer.consultationRequest,
		}),
		hasNotification() {
			return !this.viewedRecipientIds.includes(this.recipientId)
		},
		toolbar() {
			return {
				Messages: {
					group: 'right',
					icon: 'email',
					hasNotification: this.hasNotification,
					action: this.toggleRequestInfoDialog,
				},
			}
		},
	},
	async mounted() {
		const viewedRecipientIds = storage.getItem('viewedRecipientIds')
		if (viewedRecipientIds) this.viewedRecipientIds = viewedRecipientIds.split(',')
		await this.getConsultationRequestViewerVmAsync({
			id: this.id,
			clinicCode: this.clinicCode,
			recipientId: this.recipientId,
		})
	},
	methods: {
		...mapActions(['getConsultationRequestViewerVmAsync']),
		openInNewWindow(url) {
			window.open(url)
		},
		toggleRequestInfoDialog() {
			if (!this.requestInfoDialog.isOpen && this.hasNotification) this.storeViewedRecipientId()
			this.requestInfoDialog.isOpen = !this.requestInfoDialog.isOpen
		},
		storeViewedRecipientId() {
			const MAX_STORED_IDS = 50
			this.viewedRecipientIds.push(this.recipientId)
			this.viewedRecipientIds.splice(0, this.viewedRecipientIds.length - MAX_STORED_IDS)
			storage.setItem('viewedRecipientIds', this.viewedRecipientIds.join(','))
		},
		contactSender() {
			const mailto = `mailto:${this.consultationRequest.toEmail}?subject=${encodeURI(
				'RE: ' + this.consultationRequest.subject
			)}`
			window.open(mailto, '_self')
		},
		importIntoKeystone() {
			this.$api.legacy.openImporter({
				clinicCode: this.clinicCode,
				consultationRequestId: this.id,
			})
		},
		downloadStudy(imageType) {
			try {
				GoogleAnalytics.sendGAEvent('Consultation Request', {
					event_category: 'Download',
					event_label: imageType === 'previewImage' ? 'JPEG' : 'DICOM'
				})
			}
			catch (err) {
				console.log(err)
			}
			this.$api.file.downloadConsultationRequest({
				downloadType: imageType === 'previewImage' ? 0 : 1,
				clinicCode: this.clinicCode,
				id: this.id,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.data-table thead>tr>th {
	position: initial;
}
</style>
